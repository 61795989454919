import React from 'react';
import '../../styles.css'


const Header = () => {

  return (
    <>

        <header id="topnav" className="defaultscroll sticky">

        <div className='container'>
                
                <a className="logo" href="">
                    <img src="assets/image/OPSIN_logo_1.svg" height="54" className="logo-light-mode" alt="" />
                    <img src="assets/image/OPSIN_logo_1.svg" height="54" className="logo-dark-mode" alt="" />
                </a>
               

                <div className="menu-extras">
                    <div className="menu-item">
                        
                        <a className="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                        
                    
                    </div>
         

            </div>

           
                {/* <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item mb-0">
                        <a href="javascript:void(0)" className="btn btn-icon btn-soft-light">
                            <img src="images/app/app-store.png" className="avatar avatar-ex-small p-1" alt="" />
                        </a>
                    </li>
                   
                    <li className="list-inline-item mb-0">
                        <a href="javascript:void(0)" className="btn btn-icon btn-soft-light">
                            <img src="images/app/play-store.png" className="avatar avatar-ex-small p-1" alt="" />
                        </a>
                    </li>
                </ul> */}
            
                <div id="navigation">
                    <ul className="navigation-menu">
                        <li><a href="" className="sub-menu-item">Home</a></li>
                        <li><a href="" className="sub-menu-item">About</a></li>
                        <li><a href="" className="sub-menu-item">Features</a></li>
                        <li><a href="" className="sub-menu-item">Pricing</a></li>
                        <li><a href="" className="sub-menu-item">Login</a></li>
                        <li><a href="" className="sub-menu-item">Free Trial</a></li>
                    </ul>
                    
                
                </div>
                </div>
        </header>
      
      
    </>
  );
}

export default Header;
