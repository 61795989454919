import logo from './logo.svg';
import './App.css';
import Navigation from './components/navigation/Navigation';
import HttpsRedirect from 'react-https-redirect';

function App() {
  return (
    <div className="App">
       <HttpsRedirect>
         <Navigation />
       </HttpsRedirect>
     
    </div>
  );
}

export default App;
