import React from "react";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";
import "../../styles.css";


const Home = () => {
  const imgMobile = {
   
    //top: '-180px',
    position: 'relative',
    
  };
  const banner = {
    background: `url(assets/image/banner-image.jpg) center center / cover no-repeat`,
  }
  return (
   <>
      <Header />
    
        <section className="home-slider position-relative">
            <div id="carouselExampleInterval" className="carousel slide carousel-fade" data-bs-ride="carousel">
                {/* <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div> */}

                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="bg-home slider-rtl-2 d-flex align-items-center" style={banner}>
                            <div className="bg-overlay bg-overlay-white opacity-5"></div>
                            <div className="container">
                                <div className="row align-items-center mt-5">
                                    <div className="col-lg-6 col-md-7">
                                        <div className="title-heading-text-white mt-4">                                    
                                            <h1 className="display-4 fw-bold mb-3 text-white">Revolutionizing Scientific Experiment Documentation</h1>
                                            <p className="para-desc text-white">Opsin's cutting-edge technology allows researchers to seamlessly record, document, search, and share their experiments like never before</p>
                                            {/* <div className="mt-4">
                                                <a href="javascript:void(0)" className="btn btn-primary">Start Your Free Trial</a>
                                            
                                                <a href="javascript:void(0)" className="btn btn-primary ms-1">Watch Demo</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="carousel-item" data-bs-interval="3000">
                        <div className="bg-home slider-rtl-1 d-flex align-items-center">
                            <div className="bg-overlay bg-overlay-white opacity-5"></div>
                            <div className="container">
                                <div className="row align-items-center mt-5">
                                    <div className="col-lg-7 col-md-7">
                                        <div className="title-heading mt-4">
                                            <h1 className="display-4 fw-bold mb-3 text-black">Revolutionizing scienticfic experiment docmentation </h1>
                                            <p className="para-desc text-black">Banner Loreum ipsum Banner Loreum ipsum Banner Loreum ipsum Banner Loreum ipsum Banner Loreum ipsum</p>
                                            <div className="mt-4">
                                                <a href="javascript:void(0)" className="btn btn-soft-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="bg-home slider-rtl-3 d-flex align-items-center" >
                            <div className="bg-overlay bg-overlay-white opacity-5"></div>
                            <div className="container">
                                <div className="row align-items-center mt-5">
                                    <div className="col-lg-7 col-md-7">
                                        <div className="title-heading mt-4">
                                            <h1 className="display-4 fw-bold mb-3 text-black">Revolutionizing scienticfic experiment docmentation </h1>
                                            <p className="para-desc text-black">Banner Loreum ipsum Banner Loreum ipsum Banner Loreum ipsum Banner Loreum ipsum Banner Loreum ipsum</p>
                                            <div className="mt-4">
                                                <a href="javascript:void(0)" className="btn btn-soft-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>

      
      <section className="" style={{backgroundColor:"#07171E"}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5">
              <div className="app-feature-shape-left position-relative">
                <div className="text-center text-md-start">
                  <img src="assets/image/phone_1.png" className="img-fluid" alt="" style={{
   position: 'relative',
   
 }} />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div className="section-title">
              <img src="assets/image/icon_placeholder.svg" class="m-1" height="50" alt="" />
              <h1 className="title text-white mb-3">
                  Seamless Recording
                </h1>
                <p className="para-desc text-white">
                Document your scientific experiments with ease. 
                Audio and video record your processes with our intuitive platform
                </p>
               
                {/* <div className="mt-4">
                  <a href="javascript:void(0)" className="mt-3 h6 text-primary">
                    Find Out More <i className="uil uil-angle-right-b"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="" style={{backgroundColor:"#204357"}}>

        <div className="container" >
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
              <div className="section-title">
              <img src="assets/image/icon_placeholder.svg" class="m-1" height="50" alt="" />
                <h1 className="title text-white mb-3">
                  Automated Documentation
                </h1>
                <p className="para-desc text-white">
                Save time and resources. 
                Opsin automatically creates detailed experiment sections from your recordings.
                </p>
                {/* <div className="mt-4">
                  <a href="javascript:void(0)" className="btn btn-primary">
                    Learn More <i className="uil uil-angle-right-b"></i>
                  </a>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-5 order-1 order-md-2">
              <div className="app-feature-shape-right position-relative">
                <div className="text-center text-md-end">
                  <img src="assets/image/phone_2.png" className="img-fluid" alt="" style={imgMobile} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="" style={{backgroundColor:"#598391"}}>
        <div className="container" >
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5">
              <div className="app-feature-shape-left position-relative">
                <div className="text-center text-md-start">
                  <img src="assets/image/phone_3.png" className="img-fluid" alt="" style={imgMobile}  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div className="section-title">
              <img src="assets/image/icon_placeholder.svg" class="m-1" height="50" alt="" />
                <h1 className="title text-white mb-3">
                  Easy-to-Use Interface
                </h1>
                <p className="para-desc text-white">
                Navigate and manage your experiment documentation effortlessly with our user-friendly interface.
                </p>
               
                {/* <div className="mt-4">
                  <a href="javascript:void(0)" className="btn btn-primary">
                    Read More <i className="uil uil-angle-right-b"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="" style={{backgroundColor:"#ACC2AF"}}>
        <div className="container" >
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
              <div className="section-title">
              <img src="assets/image/icon_placeholder.svg" class="m-1" height="50" alt="" />
                <h1 className="title text-black mb-3">
                Advanced Search
                </h1>
                <p className="para-desc text-black">
                Never lose track of your work. 
                Quickly locate specific segments from past experiments using our sophisticated search function.
                </p>
                {/* <div className="mt-4">
                  <a href="javascript:void(0)" className="btn btn-primary">
                    Learn More <i className="uil uil-angle-right-b"></i>
                  </a>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-5 order-1 order-md-2">
              <div className="app-feature-shape-right position-relative">
                <div className="text-center text-md-end">
                  <img src="assets/image/phone_4.png" className="img-fluid" alt="" style={imgMobile} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        <section className="" style={{backgroundColor:"#EEF7E0"}}>


        <div className="container" >
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5">
              <div className="app-feature-shape-left position-relative">
                <div className="text-center text-md-start">
                  <img src="assets/image/phone_5.png" className="img-fluid" alt="" style={imgMobile}  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div className="section-title">
              <img src="assets/image/icon_placeholder.svg" class="m-1" height="50" alt="" />
                <h1 className="title mb-3">
                Simplified Experiment Reproduction
                </h1>
                <p className="para-desc text-black">
                Facilitate the reproducibility of your experiments. Share your work easily with your team or the global scientific community.
                </p>
               
                {/* <div className="mt-4">
                  <a href="javascript:void(0)" className="btn btn-primary">
                    Read More <i className="uil uil-angle-right-b"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>



   
        <section className="section" style={{backgroundColor:"#204357"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h4 className="title text-white mb-4">Pricing</h4>
                            <p className="text-white para-desc mx-auto mb-0">Straightforward Pricing for Every Research Need</p>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-12 mt-4 pt-2">
                        <div className="text-center">
                            <ul className="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2" id="pills-tab" role="tablist">
                                <li className="nav-item d-inline-block">
                                    <a className="nav-link px-3 rounded-pill active monthly" id="Monthly" data-bs-toggle="pill" href="#Month" role="tab" aria-controls="Month" aria-selected="true">Monthly</a>
                                </li>
                                <li className="nav-item d-inline-block">
                                    <a className="nav-link px-3 rounded-pill yearly" id="Yearly" data-bs-toggle="pill" href="#Year" role="tab" aria-controls="Year" aria-selected="false">Yearly <span className="badge rounded-pill bg-success">15% Off </span></a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade active show" id="Month" role="tabpanel" aria-labelledby="Monthly">    
                                <div className="row">
                                    <div className="col-lg-4 col-md-8 col-12 mt-4 pt-2">
                                        <div className="card pricing pricing-primary bg-light rounded border-0">
                                            <div className="card-body py-5" style={{backgroundColor:"#EEF7E0"}}>
                                                <h6 className="title text-uppercase fw-bold mb-4">Opsin Audio</h6>
                                                <div className="d-flex mb-4">
                                                    <span className="h4 mb-0 mt-2">£</span>
                                                    <span className="price h1 mb-0">9.99</span>
                                                    <span className="h4 align-self-end mb-1">/mo</span>
                                                </div>
                    
                                                <ul className="list-unstyled mb-0 ps-0">
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Unlimited experiments</li>
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Audio recording </li>
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Transcribing</li>
                                                </ul>
                                                <a href="javascript:void(0)" className="btn btn-primary mt-4">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div className="col-lg-4 col-md-8 col-12 mt-4 pt-2">
                                        <div className="card pricing pricing-primary starter-plan shadow rounded border-0">
                                            <div className="card-body py-5" style={{backgroundColor:"#ACC2AF"}}>
                                                <h6 className="title text-uppercase fw-bold text-black mb-4">Opsin Video</h6>
                                                <div className="d-flex mb-4">
                                                    <span className="h4 mb-0 mt-2">£</span>
                                                    <span className="price h1 mb-0">25</span>
                                                    <span className="h4 align-self-end mb-1">/mo</span>
                                                </div>
                                                
                                                <ul className="list-unstyled mb-0 ps-0">
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Unlimited experiments</li>
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>GoPro connectivity</li>
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>25 GB storage</li>

                                                </ul>
                                                <a href="javascript:void(0)" className="btn btn-primary mt-4">Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div className="col-lg-4 col-md-8 col-12 mt-4 pt-2">
                                        <div className="card pricing pricing-primary bg-light rounded border-0">
                                            <div className="card-body py-5" style={{backgroundColor:"#598391"}}>
                                                <h6 className="title text-white text-uppercase fw-bold mb-4">Opsin Vision</h6>
                                                <div className="d-flex mb-4">
                                                    <span className="h4 text-white mb-0 mt-2">£</span>
                                                    <span className="price text-white h1 mb-0">50</span>
                                                    <span className="h4 text-white align-self-end mb-1">/mo</span>
                                                </div>
                    
                                                <ul className="list-unstyled mb-0 ps-0">
                                                    <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Coming Soon</li>
                                                    
                                                </ul>
                                                <a href="javascript:void(0)" className="btn btn-primary mt-4">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                
                                   
                                </div>
                            </div>
    
                            {/* <div className="tab-pane fade" id="Year" role="tabpanel" aria-labelledby="Yearly">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                        <div className="pricing pricing-primary bg-light py-5 p-4 rounded">
                                            <h6 className="title text-uppercase fw-bold mb-4">Cloud Hosting</h6>
                                            <div className="d-flex mb-4">
                                                <span className="h4 mb-0 mt-2">$</span>
                                                <span className="price h1 mb-0">0</span>
                                                <span className="h4 align-self-end mb-1">/mo</span>
                                            </div>
                
                                            <ul className="list-unstyled mb-0 ps-0">
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>2 GB Memory</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>10 Free Optimization</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>24/7 support</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Content Optimization</li>
                                            </ul>
                                            <a href="javascript:void(0)" className="btn btn-primary mt-4">Buy Now</a>
                                        </div>
                                    </div>
                
                                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                        <div className="pricing pricing-primary starter-plan shadow py-5 p-4 rounded">
                                            <h6 className="title text-uppercase fw-bold text-primary mb-4">Dedicated Hosting</h6>
                                            <div className="d-flex mb-4">
                                                <span className="h4 mb-0 mt-2">$</span>
                                                <span className="price h1 mb-0">29</span>
                                                <span className="h4 align-self-end mb-1">/mo</span>
                                            </div>
                                            
                                            <ul className="list-unstyled mb-0 ps-0">
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>4 GB Memory</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>10 Free Optimization</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>24/7 support</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Content Optimization</li>
                                            </ul>
                                            <a href="javascript:void(0)" className="btn btn-primary mt-4">Get Started</a>
                                        </div>
                                    </div>
                
                                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                        <div className="pricing pricing-primary bg-light py-5 p-4 rounded">
                                            <h6 className="title text-uppercase fw-bold mb-4">VPS Hosting</h6>
                                            <div className="d-flex mb-4">
                                                <span className="h4 mb-0 mt-2">$</span>
                                                <span className="price h1 mb-0">39</span>
                                                <span className="h4 align-self-end mb-1">/mo</span>
                                            </div>
                
                                            <ul className="list-unstyled mb-0 ps-0">
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>8 GB Memory</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>10 Free Optimization</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>24/7 support</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Content Optimization</li>
                                            </ul>
                                            <a href="javascript:void(0)" className="btn btn-primary mt-4">Buy Now</a>
                                        </div>
                                    </div>
                
                                    <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                        <div className="pricing pricing-primary bg-light py-5 p-4 rounded">
                                            <h6 className="title text-uppercase fw-bold mb-4">Shared Hosting</h6>
                                            <div className="d-flex mb-4">
                                                <span className="h4 mb-0 mt-2">$</span>
                                                <span className="price h1 mb-0">49</span>
                                                <span className="h4 align-self-end mb-1">/mo</span>
                                            </div>
                
                                            <ul className="list-unstyled mb-0 ps-0">
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>16 GB Memory</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>10 Free Optimization</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>24/7 support</li>
                                                <li className="text-muted mb-0"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Content Optimization</li>
                                            </ul>
                                            <a href="javascript:void(0)" className="btn btn-primary mt-4">Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>     */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <Footer />
      </>
  );
};

export default Home;
