import React from 'react';


const Footer = () => {
  return (
    <>
    <footer className="footer" style={{backgroundColor:"#111C25"}}>    
            <div className="container" >
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60">
                            <div className="row">
                                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <a href="#" className="logo-footer">
                                        <img src="assets/image/OPSIN_logo_1.svg" height="54" alt="" />
                                    </a>
                                    <p className="mt-4">Start your work with Opsin and get seamless recording and searching.</p>
                                    <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a></li>
                                        <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a></li>
                                        <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a></li>
                                        <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a></li>
                                    </ul>
                                </div>
                               
                                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Opsin</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> About</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Features</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Pricing</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Help Center</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Contact</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Free Trial</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Login</a></li>
                                    </ul>
                                </div>
                               
                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Usefull Links</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Terms & Conditions</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Privacy Policy</a></li>
                                        <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Contact Us</a></li>
                                       
                                    </ul>
                                </div>
           
                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Newsletter</h5>
                                    <p className="mt-4">Stay updated with the latest from Opsin</p>
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="foot-subscribe mb-3">
                                                    <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="mail" className="fea icon-sm icons"></i>
                                                        <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="d-grid">
                                                    <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Subscribe" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="text-sm-start">
                                <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> OPSIN <a href="" target="_blank" className="text-reset">2023-2025</a>.</p>
                            </div>
                        </div>
   
                        <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul className="list-unstyled text-sm-end mb-0">
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="images/payments/american-ex.png" className="avatar avatar-ex-sm" title="American Express" alt="" /></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="images/payments/discover.png" className="avatar avatar-ex-sm" title="Discover" alt="" /></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="images/payments/master-card.png" className="avatar avatar-ex-sm" title="Master Card" alt=""/></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="images/payments/paypal.png" className="avatar avatar-ex-sm" title="Paypal" alt="" /></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="images/payments/visa.png" className="avatar avatar-ex-sm" title="Visa" alt="" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
 

        
        <div className="offcanvas offcanvas-end bg-white shadow" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header p-4 border-bottom">
                <h5 id="offcanvasRightLabel" className="mb-0">
                    <img src="assets/image/OPSIN_logo_1.svg" height="24" className="light-version" alt="" />
                    <img src="assets/image/OPSIN_logo_1.svg" height="24" className="dark-version" alt="" />
                </h5>
                <button type="button" className="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas" aria-label="Close"><i className="uil uil-times fs-4"></i></button>
            
            </div>
            <div className="offcanvas-body p-4">
                <div className="row">
                    <div className="col-12">
                        <img src="images/contact.svg" className="img-fluid d-block mx-auto" style={{}} alt="" />
                        <div className="card border-0 mt-5" style={{}}>
                            <div className="card-body p-0">
                                <form method="post" name="myForm" onsubmit="return validateForm()">
                                    <p id="error-msg" className="mb-0"></p>
                                    <div id="simple-msg"></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                <div className="form-icon position-relative">
                                                    <i data-feather="user" className="fea icon-sm icons"></i>
                                                    <input name="name" id="name" type="text" className="form-control ps-5" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>
   
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                <div className="form-icon position-relative">
                                                    <i data-feather="mail" className="fea icon-sm icons"></i>
                                                    <input name="email" id="email" type="email" className="form-control ps-5" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
   
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Subject</label>
                                                <div className="form-icon position-relative">
                                                    <i data-feather="book" className="fea icon-sm icons"></i>
                                                    <input name="subject" id="subject" className="form-control ps-5" placeholder="subject :" />
                                                </div>
                                            </div>
                                        </div>
   
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                <div className="form-icon position-relative">
                                                    <i data-feather="message-circle" className="fea icon-sm icons clearfix"></i>
                                                    <textarea name="comments" id="comments" rows="4" className="form-control ps-5" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-grid">
                                                <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offcanvas-footer p-4 border-top text-center">
                <ul className="list-unstyled social-icon social mb-0">
                    <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-shopping-cart align-middle" title="Buy Now"></i></a></li>
                    <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-dribbble align-middle" title="dribbble"></i></a></li>
                    <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                    <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-instagram align-middle" title="instagram"></i></a></li>
                    <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-twitter align-middle" title="twitter"></i></a></li>
                    <li className="list-inline-item mb-0"><a href="" className="rounded"><i className="uil uil-envelope align-middle" title="email"></i></a></li>
                    <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-globe align-middle" title="website"></i></a></li>
                </ul>
            </div>
        </div>
     

        

        
        <a href="#" onclick="topFunction()" id="back-to-top" className="back-to-top fs-5"><i data-feather="arrow-up" className="fea icon-sm icons align-middle"></i></a>
       
       
        
        
 
        </>
        
  );
}

export default Footer;
